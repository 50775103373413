/* You can add global styles to this file, and also import other style files */



@font-face {
    font-family: 'Roboto' sans-serif;
    font-weight: bold;
    font-style: normal;
}


